// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-shops-jsx": () => import("/opt/build/repo/src/pages/shops.jsx" /* webpackChunkName: "component---src-pages-shops-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-resources-jsx": () => import("/opt/build/repo/src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-blog-jsx": () => import("/opt/build/repo/src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-post-category-jsx": () => import("/opt/build/repo/src/templates/PostCategory.jsx" /* webpackChunkName: "component---src-templates-post-category-jsx" */),
  "component---src-templates-post-tag-jsx": () => import("/opt/build/repo/src/templates/PostTag.jsx" /* webpackChunkName: "component---src-templates-post-tag-jsx" */),
  "component---src-templates-author-jsx": () => import("/opt/build/repo/src/templates/Author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-alternatives-jsx": () => import("/opt/build/repo/src/templates/Alternatives.jsx" /* webpackChunkName: "component---src-templates-alternatives-jsx" */),
  "component---src-templates-alternative-category-jsx": () => import("/opt/build/repo/src/templates/AlternativeCategory.jsx" /* webpackChunkName: "component---src-templates-alternative-category-jsx" */),
  "component---src-templates-alternative-tag-jsx": () => import("/opt/build/repo/src/templates/AlternativeTag.jsx" /* webpackChunkName: "component---src-templates-alternative-tag-jsx" */),
  "component---src-templates-alternative-jsx": () => import("/opt/build/repo/src/templates/Alternative.jsx" /* webpackChunkName: "component---src-templates-alternative-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alternative-categories-jsx": () => import("/opt/build/repo/src/pages/alternative-categories.jsx" /* webpackChunkName: "component---src-pages-alternative-categories-jsx" */),
  "component---src-pages-alternative-tags-jsx": () => import("/opt/build/repo/src/pages/alternative-tags.jsx" /* webpackChunkName: "component---src-pages-alternative-tags-jsx" */),
  "component---src-pages-search-jsx": () => import("/opt/build/repo/src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

